import { EventMessage, FeatureCore } from '@dreamcommerce/star_core';
import { BASKET_EVENTS } from '@storefrontRoot/models/basket/events/basket_events_constants';

export class BasketUpdatedHandler extends FeatureCore {
    public readonly moduleName = 'BasketUpdatedHandler';

    constructor(callback: (event: EventMessage<CustomEvent>) => void) {
        super();

        this.eventBus.on(BASKET_EVENTS.basketUpdated, callback);
    }
}
