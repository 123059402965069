import { FeatureApi } from '@dreamcommerce/star_core';
import {
    IStorefrontSettingsQuery,
    TInpostPayWidgetSettings,
    TP24InstallmentsSettings,
    TSkinSettings
} from '@storefrontFeatures/storefront_settings/state/storefront_settings_types';
import { Observable } from 'rxjs';
import { TCurrencyIsoCode } from '@dreamcommerce/star_core/build/esm/packages/star_core/src/classes/currency/currency_types';
import { TLocaleIsoUnderscore } from '@dreamcommerce/star_core/build/esm/packages/star_core/src/classes/locale/locale_types';
import { select } from '@ngneat/elf';
import { StorefrontSettingsService } from '@storefrontRoot/features/storefront_settings/state/services/storefront_settings_service';
import { STOREFRONT_SETTINGS_NAMES } from '@storefrontRoot/features/storefront_settings/storefront_settings_constants';
import {
    IStorefrontSettingsApi,
    StorefrontSettingsApiConstructorOptions,
    TLocaleOptions
} from '@storefrontRoot/features/storefront_settings/storefront_settings_types';
import { Country } from '@storefrontRoot/models/country/country';

export class StorefrontSettingsApi extends FeatureApi implements IStorefrontSettingsApi {
    readonly moduleName = STOREFRONT_SETTINGS_NAMES.api;

    readonly #query: IStorefrontSettingsQuery;
    readonly #service: StorefrontSettingsService;

    constructor({ service, query }: StorefrontSettingsApiConstructorOptions) {
        super();

        this.#query = query;
        this.#service = service;
    }

    public getSkinSettings(): TSkinSettings {
        return this.#query.getSkinSettings();
    }

    public getPageSettings(): Record<string, string> {
        return this.#query.getPageSettings();
    }

    public getInpostPayWidgetSettings(): TInpostPayWidgetSettings | null {
        return this.#query.getInpostPayWidgetSettings();
    }

    public getP24InstallmentsSettings(): TP24InstallmentsSettings | null {
        return this.#query.getP24InstallmentsSettings();
    }

    public selectSkinSettings$(): Observable<TSkinSettings> {
        return this.#query.selectSkinSettings$();
    }

    public selectPageSettings$(): Observable<Record<string, string>> {
        return this.#query.selectPageSettings$();
    }

    public selectInpostPayWidgetSettings$(): Observable<TInpostPayWidgetSettings | null> {
        return this.#query.selectInpostPayWidgetSettings$();
    }

    public selectP24InstallmentsSettings$(): Observable<TP24InstallmentsSettings | null> {
        return this.#query.selectP24InstallmentsSettings$();
    }

    public selectFrontstoreApiUrl$(): Observable<string> {
        return this.#query.selectFrontstoreApiUrl$();
    }

    public getLocales(options?: TLocaleOptions): TLocaleOptions {
        const { locale: skinLocale, currency: skinCurrency } = this.getSkinSettings();

        const locale = options?.locale ?? (skinLocale as TLocaleIsoUnderscore);
        const currency = options?.currency ?? (skinCurrency as TCurrencyIsoCode);

        return { locale, currency };
    }

    public selectLocales$(options?: TLocaleOptions): Observable<TLocaleOptions> {
        return this.selectSkinSettings$().pipe(
            select(({ locale: skinLocale, currency: skinCurrency }) => {
                const locale = options?.locale ?? (skinLocale as TLocaleIsoUnderscore);
                const currency = options?.currency ?? (skinCurrency as TCurrencyIsoCode);

                return { locale, currency };
            })
        );
    }

    public getCountries(): Country[] {
        return this.#service.getCountries();
    }

    public selectCountries$(): Observable<Country[]> {
        return this.#service.selectCountries$();
    }

    public getCountryById(id: number): Country | null {
        return this.#service.getCountryById(id);
    }

    public selectCountryById$(id: number): Observable<Country | null> {
        return this.#service.selectCountryById$(id);
    }

    public getCountryByCode(code: string): Country | null {
        return this.#service.getCountryByCode(code);
    }

    public selectCountryByCode$(code: string): Observable<Country | null> {
        return this.#service.selectCountryByCode$(code);
    }
}
