const componentName = 'product-ask-questions';
const featureName = 'ProductAskQuestions';

export const PRODUCT_ASK_QUESTIONS_NAMES = {
    feature: componentName,
    component: componentName,
    api: `${featureName}Api`,
    httpApi: `${featureName}HttpApi`,
    service: `${featureName}Service`,
    store: ''
} as const;

export const PRODUCT_ASK_QUESTIONS_EVENTS = {
    askForProductSuccess: 'askForProductSuccess'
} as const;

export const PRODUCT_ASK_QUESTIONS_FLASH_MESSENGER_CONTAINER_NAME = 'product-ask-questions-flash-messenger';
