export const LOYALTY_PROGRAM_POINTS_NAMES = {
    feature: 'loyalty-program-points',
    component: 'loyalty-program-points'
} as const;

export const LOYALTY_PROGRAM_POINTS_CLASS_NAMES = {
    pointsRequired: 'js__points-required',
    pointsEarned: 'js__points-earned',
    
} as const;



