import { FeatureCore, IFeatureSystemApi } from '@dreamcommerce/star_core';
import { INPOST_PAY_NAMES } from '@storefrontFeatures/inpost_pay/inpost_pay_constants';
import { PageManagerLoadedHandler } from '@storefrontCoreFeatures/page_management/events/page_manager_loaded_handler';
import { INPOST_PAY_V2_NAMES } from '@storefrontFeatures/inpost_pay/inpost_pay_v2_constants';

export class InpostPayObserver extends FeatureCore {
    public moduleName = 'InpostPayObserver';

    readonly #featureSystemApi: IFeatureSystemApi;

    #isRegistered = false;

    constructor(featureSystemApi: IFeatureSystemApi) {
        super();

        this.#featureSystemApi = featureSystemApi;

        this._performRegistration();

        new PageManagerLoadedHandler(() => {
            this._performRegistration();
        });

        this._setObserver();
    }

    private _performRegistration() {
        if (this._shouldRegisterInpostPay()) {
            this._registerInpostPay();
        }

        if (this._shouldRegisterInpostPayV2()) {
            this._registerInpostPayV2();
        }
    }

    private _setObserver(): void {
        const observer = new MutationObserver((mutations: MutationRecord[]) => {
            for (const { addedNodes } of mutations)
                if (addedNodes.length > 0) {
                    this._performRegistration();

                    if (this.#isRegistered) {
                        observer.disconnect();
                    }
                }
        });

        observer.observe(document.body, {
            attributes: false,
            childList: true,
            subtree: true
        });
    }

    private _shouldRegisterInpostPay(): boolean {
        return !!document.querySelector('inpost-izi-button[data-v1]') || !!document.querySelector('inpost-thank-you[data-v1]');
    }

    private _shouldRegisterInpostPayV2(): boolean {
        return !!document.querySelector('inpost-izi-button[data-v2]') || !!document.querySelector('inpost-thank-you[data-v2]');
    }

    private _registerInpostPay() {
        if (!this.#isRegistered) {
            this.#featureSystemApi.registerDynamic(INPOST_PAY_NAMES.feature);
            this.#isRegistered = true;
        }
    }

    private _registerInpostPayV2() {
        if (!this.#isRegistered) {
            this.#featureSystemApi.registerDynamic(INPOST_PAY_V2_NAMES.feature);
            this.#isRegistered = true;
        }
    }
}
