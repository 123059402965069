export const FILTERS_NAMES = {
    feature: 's-filters',
    component: 's-filters',
    api: '',
    service: '',
    store: ''
} as const;

export const FILTERS_HORIZONTAL_NAMES = {
    feature: 'filters-horizontal',
    component: 'filters-horizontal',
    api: '',
    service: '',
    store: ''
} as const;

export const FILTERS_VERTICAL_NAMES = {
    feature: 'filters-vertical',
    component: 'filters-vertical',
    api: '',
    service: '',
    store: ''
} as const;
